import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import "assets/fonts/Roboto/Roboto-Regular.ttf";
import "assets/fonts/Roboto/Roboto-Medium.ttf";
import "assets/fonts/Roboto/Roboto-Bold.ttf";
import * as Sentry from "@sentry/react";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";

const history = createBrowserHistory();
const post_hog_key = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
const post_hog_api_key = process.env.REACT_APP_PUBLIC_POSTHOG_HOST;

ReactGA.initialize("UA-180095391-1");
ReactGA.pageview(window.location.pathname + window.location.hash);
history.listen((location) => {
  ReactGA.pageview(location.pathname + location.hash);
});

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const postHogCredsFound = post_hog_key && post_hog_api_key;
if (postHogCredsFound) {
  posthog.init(post_hog_key, {
    api_host: post_hog_api_key,
  });
}

const RenderApp = () => {
  if (postHogCredsFound)
    return (
      <PostHogProvider client={posthog}>
        <App />
      </PostHogProvider>
    );
  else return <App />;
};
ReactDOM.render(
  <Sentry.ErrorBoundary>
    <React.StrictMode>
      <RenderApp />
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
export default history;
